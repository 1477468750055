import { io } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
// const URL = process.env.NODE_ENV === 'production' ? undefined : 'wss://localhost:3000';
// const URL = "ws://10.108.0.2:3000?event=63f27b8b289df460baeab8b9";

const URL = "https://snapp-api.appics.dev/?event=63f27b8b289df460baeab8b9";
// const URL = "ws://localhost:3000/?event=63f27b8b289df460baeab8b9";

export const socket = io(URL, { autoConnect: false, secure: true });
