import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import "../App.css";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Caption from "../components/Caption";
import Hashtag from "../components/Hashtag";
import Social from "../components/Social";

import logo from "../assets/logo_snapp.png";
import button_fullscreen from "../assets/fullscreen.png";
import fallback_landscape from "../assets/fallback_landscape.mp4";

import Lottie from "react-lottie";
import * as animCelebration from "../assets/anim_celebration.json";

import { socket } from "../socket";

const AutoplaySlider = withAutoplay(AwesomeSlider);

function EventPage() {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [posts, setPosts] = useState([]);
  const [selected, setSelected] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPost, setCurrentPost] = useState(null);
  const [fallback, setFallback] = useState(false);
  const [animationDisplay, setAnimationDisplay] = useState("none");
  let { eventId } = useParams();
  const [event, setEvent] = useState({
    _id: eventId,
    // hashtags: ["#BrasilGameShow, #SnappDigital"],
    // hashtags: ["#jotaja"],
    // hashtags: ["#therockbar"],
    hashtags: ["#weddingthiagoandamber"],
    // hashtags: ["#snappdigital"],
    interval: 8000,
  });

  const [isSocketConnected, setIsSocketConnected] = useState(socket.connected);

  useEffect(() => {
    getData();

    const onSocketConnect = () => {
      setIsSocketConnected(true);
    };

    const onSocketDisconnect = () => {
      setIsSocketConnected(false);
    };

    const onPublish = (post) => {
      post.source = post.storedMedia;

      console.log("==================================================");
      console.log("onPublish");

      setPosts((oldPost) => [...oldPost, post]);
      showAnimation();
    };

    const onDisapprove = (data) => {
      setPosts((oldPost) => {
        return oldPost.filter((d) => d._id !== data._id);
      });

      window.location.reload(false);

      // setFallback(true, () => {});

      // setTimeout(() => {
      //   getData();
      // }, 50);

      // setTimeout(() => {
      //   setPosts((oldPost) => {
      //     return oldPost.filter((d) => d._id !== data._id);
      //   });
      // }, 250);

      // setTimeout(() => {
      //   setPosts((oldPost) => {
      //     return oldPost.filter((d) => d._id !== data._id);
      //   });
      // }, 500);

      // const update = JSON.parse(JSON.stringify(posts));
      // const disapproveIndex = update.findIndex((d) => d._id == data._id);
      // debugger;
      // if (disapproveIndex === currentIndex) {
      //   update[currentIndex].fallback = true;
      // }

      // setPosts(update);

      // console.log(posts);

      // ==================================================

      // setPosts((oldPost) => {
      //   // return oldPost.filter((d) => d._id !== data._id);
      //   debugger;
      //   return oldPost;
      // });
    };

    const showAnimation = () => {
      if (animationDisplay == "none") {
        setAnimationDisplay("block");
        setTimeout(() => {
          setAnimationDisplay("none");
        }, 2500);
      }
    };

    socket.on("connect", onSocketConnect);
    socket.on("disconnect", onSocketDisconnect);
    socket.on("PUBLISH", onPublish);
    socket.on("PUBLISH_MANUAL", onPublish);
    socket.on("DISAPPROVE", onDisapprove);

    socket.connect();

    return () => {
      socket.off("connect", onSocketConnect);
      socket.off("disconnect", onSocketDisconnect);
      socket.off("PUBLISH", onPublish);
      socket.off("PUBLISH_MANUAL", onPublish);
      socket.off("DISAPPROVE", onDisapprove);
    };
  }, []);

  // const getEvent = () => {};

  const getData = () => {
    //   fetch(
    //     `http://localhost:3000/post?match[event]=${event._id}&match[approved]=true`,
    // {
    fetch(
      `https://snapp-api.appics.dev/post?match[event]=${event._id}&match[approved]=true`,
      {
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setData(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const setData = (data) => {
    setPosts(data);
  };

  const renderFallback = () => {
    return (
      <video
        key={new Date().getTime()}
        style={{ height: "100vh", display: !fallback ? "none" : "flex" }}
        autoPlay={true}
        muted={true}
        loop={true}
      >
        <source src={fallback_landscape} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  };

  const renderImage = (post) => {
    return (
      <div key={post._id} style={{ backgroundColor: "transparent" }}>
        <img
          style={{ height: "100vh", display: fallback ? "none" : "flex" }}
          src={post.media}
          onLoad={() => {
            setFallback(false);
          }}
          onError={() => {
            setFallback(true);
          }}
        />

        {renderFallback()}
      </div>
    );
  };

  const renderVideo = (post) => {
    return (
      <div key={post._id} style={{ backgroundColor: "transparent" }}>
        <video
          style={{ height: "100vh", display: fallback ? "none" : "flex" }}
          autoPlay={true}
          muted={true}
          loop={true}
          onLoadedData={() => {
            setFallback(false);
          }}
          onError={() => {
            setFallback(true);
          }}
        >
          <source src={post.media} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {renderFallback()}
      </div>
    );
  };

  const renderMedias = (posts) => {
    return posts.map((post) => {
      if (post.type === "IMAGE") return renderImage(post);
      else if (post.type === "VIDEO") return renderVideo(post);
      else console.log("error");
    });
  };

  const onTransitionStart = (data) => {
    //   const { nextIndex } = data;
    //   setCurrentIndex(nextIndex);
    //   console.log(currentIndex);
  };

  const onTransitionEnd = (data) => {
    const { currentIndex: index } = data;

    setCurrentIndex((data) => index);
    setCurrentPost(posts[index]);

    // console.log("==================================================");
    // console.log(currentIndex);
  };

  const intro = () => {
    return (
      <>
        <video
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          autoPlay={true}
          muted={true}
          loop={true}
        >
          <source src={fallback_landscape} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </>
    );
  };

  const fullscreen = () => {
    // document.querySelector("body").requestFullscreen();
  };

  const startupScreen = (
    <div style={{ backgroundColor: "#0095B7" }}>
      <span style={{ fontSize: "72px", color: "rgba(0, 0, 0, 0.25)" }}>♪</span>
    </div>
  );

  const carousel = () => {
    return (
      <>
        {
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animCelebration,
            }}
            style={{
              display: animationDisplay,
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: "9999",
            }}
          />
        }

        <div className="logo-container">
          {/* <img src={logo} className="logo" /> */}
          {/* <img
            src={button_fullscreen}
            className="button-fullscreen"
            onClick={fullscreen}
          /> */}
        </div>

        <AutoplaySlider
          play={true}
          cancelOnInteraction={false}
          interval={event.interval}
          organicArrows={false}
          bullets={false}
          fillParent={true}
          infinite={true}
          onTransitionStart={onTransitionStart}
          onTransitionEnd={onTransitionEnd}
          selected={selected}
        >
          {renderMedias(posts)}
        </AutoplaySlider>

        <Caption
          className="caption"
          text={currentPost?.caption || ""}
          style={{ display: fallback ? "none" : "flex" }}
        ></Caption>

        <div className="social-container">
          <Social origin={currentPost?.origin || ""} />

          <div className="hashtags-container">
            <Hashtag fontSize="48px" text={event.hashtags[0]} />
          </div>
        </div>
      </>
    );
  };

  return <div className="page">{posts.length < 2 ? intro() : carousel()}</div>;

  // return (
  //   <AutoplaySlider
  //     play={true}
  //     cancelOnInteraction={false}
  //     interval={event.interval}
  //     organicArrows={false}
  //     bullets={true}
  //     fillParent={true}
  //     infinite={true}
  //     onTransitionEnd={onTransitionEnd}
  //     media={posts}
  //   />
  // );
}

export default EventPage;
