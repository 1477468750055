import "./hashtag.css";

function Hashtag(props) {
  return (
    <div
      className="hashtag-container"
      style={{
        ...props.style,
        fontSize: props.fontSize,
        padding: props.padding,
        height: props.height,
        float: props.float,
        color: props.color,
      }}
    >
      <span>{props.text}</span>
    </div>
  );
}
export default Hashtag;
