import "./caption.css";

function Caption(props) {
  if (props.text) {
    return (
      <div
        className={`caption-container ${props.className}`}
        style={{
          ...props.style,
          backgroundColor: props.background,
          width: props.width,
          borderRadius: props.borderRadius,
        }}
      >
        <span>{props.text}</span>
      </div>
    );
  } else {
    return <></>;
  }
}

export default Caption;
