import "./social.css";

import logo_instagram from "../../assets/logo_instagram.svg";
import logo_tiktok from "../../assets/logo_tiktok.svg";
import { INSTAGRAM, TIKTOK } from "../../common/enum/origin.enum";

function Social(props) {
  const size = props.size || 96;
  let logo;

  switch (props.origin) {
    case INSTAGRAM:
      logo = logo_instagram;
      break;
    case TIKTOK:
      logo = logo_tiktok;
      break;
    default:
      logo = logo_instagram;
  }

  return (
    <img
      src={logo}
      style={{
        ...props.style,
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  );
}
export default Social;
