import { BrowserRouter, Routes, Route } from "react-router-dom";
import EventPage from "./pages/Event";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/event/:eventId" element={<EventPage />} />
      </Routes>
    </div>
  );
}

export default App;

/*
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import "./App.css";

import React, { useState, useEffect } from "react";

import Caption from "./components/Caption";
import Hashtag from "./components/Hashtag";
import Social from "./components/Social";

import logo from "./assets/logo_snapp.png";
import fallback_landscape from "./assets/fallback_landscape.mp4";

import Lottie from "react-lottie";
import * as animCelebration from "./assets/anim_celebration.json";

import { socket } from "./socket";

const AutoplaySlider = withAutoplay(AwesomeSlider);

function App() {
  const [posts, setPosts] = useState([]);
  const [currentPost, setCurrentPost] = useState(null);
  const [fallback, setFallback] = useState(false);
  const [event, setEvent] = useState({
    _id: "63f27b8b289df460baeab8b9",
    hashtags: ["#FestaTazos", "#FestaTazos90", "AppicsDigital"],
    interval: 5000,
  });

  const [isSocketConnected, setIsSocketConnected] = useState(socket.connected);

  useEffect(() => {
    getData();

    const onSocketConnect = () => {
      setIsSocketConnected(true);
    };

    const onSocketDisconnect = () => {
      setIsSocketConnected(false);
    };

    const onPublish = (post) => {
      post.source = post.storedMedia;

      setPosts((oldPost) => [...oldPost, post]);
    };

    socket.on("connect", onSocketConnect);
    socket.on("disconnect", onSocketDisconnect);
    socket.on("PUBLISH", onPublish);

    socket.connect();

    return () => {
      socket.off("connect", onSocketConnect);
      socket.off("disconnect", onSocketDisconnect);
      socket.off("PUBLISH", onPublish);
    };
  }, []);

  // const getEvent = () => {};

  const getData = () => {
    fetch(`http://localhost:3000/post?match[event]=${event._id}`, {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setData(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const setData = (data) => {
    setPosts(data);
  };

  const renderFallback = () => {
    return (
      <video
        style={{ height: "100vh", display: !fallback ? "none" : "flex" }}
        autoplay="true"
        muted="true"
        loop="true"
      >
        <source src={fallback_landscape} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  };

  const renderImage = (post) => {
    return (
      <div style={{ backgroundColor: "transparent" }}>
        <img
          style={{ height: "100vh", display: fallback ? "none" : "flex" }}
          src={post.media}
          onLoad={() => {
            setFallback(false);
          }}
          onError={() => {
            setFallback(true);
          }}
        />

        {renderFallback()}
      </div>
    );
  };

  const renderVideo = (post) => {
    return (
      <div style={{ backgroundColor: "transparent" }}>
        <video
          style={{ height: "100vh", display: fallback ? "none" : "flex" }}
          autoplay="true"
          muted="true"
          loop="true"
          onLoadedData={() => {
            setFallback(false);
          }}
          onError={() => {
            setFallback(true);
          }}
        >
          <source src={post.media} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {renderFallback()}
      </div>
    );
  };

  const renderMedias = (posts) => {
    return posts.map((post) => {
      if (post.type === "IMAGE") return renderImage(post);
      else if (post.type === "VIDEO") return renderVideo(post);
      else console.log("error");
    });
  };

  const onTransitionEnd = (data) => {
    const { currentIndex } = data;
    setCurrentPost(posts[currentIndex]);

    console.log("==================================================");
    console.log(currentIndex);
  };

  const intro = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animCelebration,
    };

    return (
      <>
        <video
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          autoplay="true"
          muted="true"
          loop="true"
        >
          <source src={fallback_landscape} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {<Lottie
          options={defaultOptions}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: "9999",
          }}
        />}
      </>
    );
  };

  const carousel = () => {
    return (
      <>
        <img src={logo} className="logo-container" />

        <AutoplaySlider
          play={true}
          cancelOnInteraction={false}
          interval={event.interval}
          organicArrows={false}
          bullets={false}
          fillParent={true}
          infinite={true}
          onTransitionEnd={onTransitionEnd}
        >
          {renderMedias(posts)}
        </AutoplaySlider>

        <Caption
          className="caption"
          text={currentPost?.caption || ""}
          style={{ display: fallback ? "none" : "flex" }}
        ></Caption>

        <div className="social-container">
          <Social origin={currentPost?.origin || ""} />

          <div className="hashtags-container">
            <Hashtag fontSize="64px" text={event.hashtags[0]} />
          </div>
        </div>
      </>
    );
  };

  return <div className="page">{posts.length < 2 ? intro() : carousel()}</div>;

  // return (
  //   <AutoplaySlider
  //     play={true}
  //     cancelOnInteraction={false}
  //     interval={event.interval}
  //     organicArrows={false}
  //     bullets={true}
  //     fillParent={true}
  //     infinite={true}
  //     onTransitionEnd={onTransitionEnd}
  //     media={posts}
  //   />
  // );
}

export default App;
*/
